import { memo, useMemo, useEffect, useState, useCallback } from "react"
import { Box, Button, Container, Heading, Grid, GridItem, Input, Textarea, VStack, Flex, Text } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import { CustomCheckbox } from "@components/Checkbox"
import { PageProps } from "@root/types/global"
import { useCheckoutContext } from "@app/providers/checkout"
import { PaymentTypes } from "@components/PaymentTypes"
import { useContent } from "@app/hooks/useContent"
import { CartRecommendations } from "./CartRecommendations"
import { CartShipping } from "./CartShipping"
import { CartSummary } from "./CartSummary"
import { CartForgetSomething } from "./CartForgetSomething"
import { CartMinimumSpendNotice } from "./CartMinimumSpendNotice"
import { CartList } from "./CartList"
import { CheckoutBtn } from "@components/CheckoutBtn"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { CartAuthorityToLeave } from "./CartAuthorityToLeave"
import { CartDeliveryInstructions } from "./CartDeliveryInstructions"
import { useMedia } from "@app/hooks/useMedia"
import { FreeSamples } from "./FreeSamples/FreeSamples"
import { useRoutes } from "@app/hooks/useRoutes"
import { useShopify } from "@app/hooks/useShopify"
import { useCart } from "@app/hooks/useCart"
import { useCore } from "@app/hooks/useCore"

import type { Location } from "@root/types/global"

type CartProps = {
  page: PageProps<any>
  location: Location
}

type ContinueShoppingButtonProps = {
  isEmptyCart?: boolean
}

const LIST_AREA = "list"
const SHIPPING_AREA = "shipping"
const FORGET_SOMETHING = "forget-something"
const SAMPLES_AREA = "samples"
const SIDEBAR_AREA = "sidebar"
const DELIVERY_AREA = "delivery"
const MINIMUM_SPEND_AREA = "minimum-spend"

const GRID_TEMPLATE_AREAS_DESKTOP = `
  "${SHIPPING_AREA}     ${SHIPPING_AREA}     ${SHIPPING_AREA}     ${MINIMUM_SPEND_AREA}"
  "${FORGET_SOMETHING}  ${FORGET_SOMETHING}  ${FORGET_SOMETHING}  ${MINIMUM_SPEND_AREA}"
  "${LIST_AREA}         ${LIST_AREA}         ${LIST_AREA}         ${SIDEBAR_AREA}"
  "${DELIVERY_AREA}     ${DELIVERY_AREA}     ${DELIVERY_AREA}     ${SIDEBAR_AREA}"
  "${SAMPLES_AREA}      ${SAMPLES_AREA}      ${SAMPLES_AREA}      ${SIDEBAR_AREA}"
`

const GRID_TEMPLATE_AREAS_MOBILE = `
  "${SHIPPING_AREA}"
  "${FORGET_SOMETHING}"
  "${LIST_AREA}"
  "${MINIMUM_SPEND_AREA}"
  "${SIDEBAR_AREA}"
  "${DELIVERY_AREA}"
  "${SAMPLES_AREA}"
`

const ContinueShoppingButton: React.FC<ContinueShoppingButtonProps> = ({ isEmptyCart = false }) => {
  const locales = useLocale(LOCALE_KEYS.CART)
  const buttonLabel = locales?.cartContinueShopping
  const { goBack } = useRoutes()

  // const link = !disabled ? url : routes.HOMEPAGE

  if (!buttonLabel) return null

  return (
    <Button variant="outline" w={isEmptyCart ? "auto" : "full"} mt={[0, 0, 4]} onClick={() => goBack({ fallback: `/collections/new` })}>
      {buttonLabel}
    </Button>
  )
}

const Cart: React.FC<CartProps> = ({ page, location }) => {
  const { count, checkout } = useCheckoutContext()
  const { getContent } = useContent()
  const content = getContent({ content: page?.content })
  const { isMedium } = useMedia()
  const {
    helpers: { isBrowser },
  } = useCore()
  const [hasGiftBoxes, setHasGiftBoxes] = useState(false)
  const [giftCardAdded, setGiftCardAdded] = useState(false)
  const [giftCardProducts, setGiftCardProducts] = useState([])
  const [showGiftFields, setShowGiftFields] = useState(false)
  const [editGiftFields, setEditGiftFields] = useState(false)
  const defaultGiftMessage = { sender: "", recipient: "", message: "" }
  const [giftMessageData, setGiftMessageData] = useState(
    isBrowser
      ? window.sessionStorage?.getItem("giftMessage")
        ? JSON.parse(sessionStorage?.getItem("giftMessage"))
        : defaultGiftMessage
      : defaultGiftMessage
  )

  const cartHasItems = useMemo(() => !!count, [count])
  const minimumAmount = 50
  const cartTotal = parseInt(checkout?.paymentDue?.amount)
  const checkoutValueReached = cartTotal >= minimumAmount
  const { getProducts } = useShopify()
  const { addToCart, loading: cartLoading, mutationLoading, updateItem } = useCart()

  const giftMessageContainsData = !!giftMessageData.sender || !!giftMessageData.recipient || !!giftMessageData.message
  const canSaveGiftMessage = !!giftMessageData.sender && !!giftMessageData.recipient && !!giftMessageData.message
  const GIFT_CARD_HANDLE = "gift-message"
  const giftFieldsEditable = giftCardAdded && !editGiftFields ? false : true

  const { cartForgetSomething, cartMessageData } = useStaticQuery<GatsbyTypes.StaticCartQuery>(graphql`
    query StaticCart {
      cartForgetSomething: sanitySettingCart {
        title
        sourceType
        bgColor
        productCount
        collection {
          shopify {
            handle
          }
        }
        products {
          shopify {
            handle
          }
        }
      }
      cartMessageData: sanitySettingCart {
        isEnabledCartMessage
        cartMessageText
      }
    }
  `)

  const addGiftCardToCart = useCallback(
    async (variantId, customAttributes) => {
      await addToCart({
        variantId,
        isSubscriptionProduct: false,
        customAttributes,
      })
    },
    [addToCart]
  )

  const updateExistingGiftCard = useCallback(
    async ({ id, variantId, quantity, customAttributes }) => {
      await updateItem(id, variantId, quantity, customAttributes)
    },
    [updateItem]
  )

  const handleGiftMessageSave = useCallback(
    async giftCards => {
      sessionStorage.setItem("giftMessage", JSON.stringify(giftMessageData))

      if (!giftCards.length) return console.log(`Couldn't fetch Gift Card Product, make sure ${GIFT_CARD_HANDLE} exists`)
      const giftCardVariantId = giftCards[0].variants[0].id

      const customAttributes = [
        { key: "_SenderName", value: giftMessageData.sender },
        { key: "_RecipientName", value: giftMessageData.recipient },
        { key: "_GiftMessage", value: giftMessageData.message },
      ]

      if (giftCardAdded) {
        // Gift Card LineItem exists, need to update it's custom attributes
        const matchingLineItem = checkout?.lineItems?.find((lineItem: any) => lineItem?.variant?.id === giftCardVariantId)
        const lineItemId = matchingLineItem.id
        updateExistingGiftCard({
          id: lineItemId,
          variantId: giftCardVariantId,
          quantity: 1,
          customAttributes,
        })
      } else {
        // Add new Gift Card LineItem
        addGiftCardToCart(giftCardVariantId, customAttributes)
      }
    },
    [checkout?.lineItems, giftMessageData, updateExistingGiftCard, addGiftCardToCart, giftCardAdded]
  )

  // Check if we have products tagged with "category:gift-boxes" in the cart
  useEffect(() => {
    setHasGiftBoxes(false)
    if (checkout?.lineItems?.length) {
      checkout?.lineItems?.map(lineItem => {
        lineItem?.variant?.product?.tags?.map(tag => {
          if (tag === "category:gift-boxes") {
            setHasGiftBoxes(true)
          }
        })
      })
    }
  }, [checkout?.lineItems])

  // If we have gift boxes, fetch giftcard products
  useEffect(() => {
    if (hasGiftBoxes) {
      const fetchGiftCardProducts = async () => {
        const results = await getProducts({ firstImages: 1, firstVariants: 1, handles: [GIFT_CARD_HANDLE] })
        if (!results?.length) return
        setGiftCardProducts(results)
      }
      fetchGiftCardProducts().catch(err => console.log("Error fetching giftcard products", err))
    }
  }, [hasGiftBoxes, getProducts, giftCardAdded])

  // Check if we have gift card products in the cart
  useEffect(() => {
    if (giftCardProducts.length) {
      const giftCardId = giftCardProducts[0].variants[0].id
      const giftCardLineItems = checkout?.lineItems?.find((lineItem: any) => lineItem?.variant?.id === giftCardId)

      if (giftCardLineItems) {
        if (!giftMessageContainsData) {
          // if user closed the tab and then re-opened it (thus clearing sessionStorage), we need to fetch the gift message data from the lineitem
          const sender = giftCardLineItems?.customAttributes?.find((attr: any) => attr.key === "_SenderName")
          const recipient = giftCardLineItems?.customAttributes?.find((attr: any) => attr.key === "_RecipientName")
          const message = giftCardLineItems?.customAttributes?.find((attr: any) => attr.key === "_GiftMessage")
          setGiftMessageData({
            sender: sender?.value || "",
            recipient: recipient?.value || "",
            message: message?.value || "",
          })
        }
        setGiftCardAdded(true)
        setEditGiftFields(false)
        setShowGiftFields(true)
      } else {
        setGiftCardAdded(false)
        setEditGiftFields(true)
      }
    } else {
      setGiftCardAdded(false)
    }
  }, [giftCardProducts, checkout?.lineItems, giftMessageContainsData])

  return (
    <>
      <Container as="section" variant="small" py={[14, 14, 27]}>
        {cartMessageData && cartMessageData.isEnabledCartMessage && (
          <Box bg="#fdf0b7" p={[4, 5]} mb="4">
            <Text>{cartMessageData.cartMessageText}</Text>
          </Box>
        )}
        <Box as="section">
          <Heading as="h1" size="h2" mb={4}>
            {page?.title}
          </Heading>
          {content}
        </Box>
        <Grid
          as="section"
          alignItems="flex-start"
          gap={8}
          templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(4, 1fr)"]}
          templateAreas={[GRID_TEMPLATE_AREAS_MOBILE, GRID_TEMPLATE_AREAS_MOBILE, GRID_TEMPLATE_AREAS_DESKTOP]}
          rowGap={0}
        >
          <GridItem area={SHIPPING_AREA}>
            {cartHasItems && (
              <>
                <CartShipping isMain />
                {!isMedium && (
                  <Box mb={4}>
                    <ContinueShoppingButton />
                  </Box>
                )}
              </>
            )}
          </GridItem>

          <GridItem area={FORGET_SOMETHING} overflowX="hidden">
            <CartForgetSomething location={location} cartForgetSomething={cartForgetSomething} />
          </GridItem>

          <GridItem area={SAMPLES_AREA} pb={[0, 0, 4]} position="relative" overflow="hidden">
            {cartHasItems && <FreeSamples />}
          </GridItem>
          <GridItem w="full" area={LIST_AREA}>
            <CartList isMain />
          </GridItem>
          <GridItem area={DELIVERY_AREA} pb={[0, 0, 8]}>
            {cartHasItems && (
              <>
                <CartAuthorityToLeave />
                <CartDeliveryInstructions />
                {/* {cartHasItems && <CartDiscount />} */}
              </>
            )}
            {!cartHasItems && checkout?.id && <ContinueShoppingButton isEmptyCart />}
          </GridItem>

          {cartHasItems && !checkoutValueReached && (
            <GridItem area={MINIMUM_SPEND_AREA} mt={[6, 6, 0]} mb={["-10px", "-10px", "0"]}>
              <CartMinimumSpendNotice minimumAmount={minimumAmount} ctaText="Checkout" showCta={false} noNegativeMargin />
            </GridItem>
          )}

          {cartHasItems && (
            <GridItem area={SIDEBAR_AREA} gridRow={["initial", "initial", "2 / 6"]}>
              {hasGiftBoxes && (
                <Box
                  bg={["unset", "unset", "brand.lightSand"]}
                  maxW={["unset", "unset", 76]}
                  overflow="hidden"
                  px={[0, 0, 6]}
                  pt={[8, 6]}
                  pb={[2, 2, 6]}
                  mb={[5]}
                  minW={76}
                >
                  <VStack alignItems="flex-start" mb="0">
                    <CustomCheckbox
                      name="add_gift_message"
                      fontSize="16px"
                      checked={showGiftFields}
                      handleChange={() => setShowGiftFields(!showGiftFields)}
                    >
                      Add Gift Card Message
                    </CustomCheckbox>
                    {showGiftFields && (
                      <>
                        <Input
                          type="text"
                          value={giftMessageData.sender}
                          variant="giftMessage"
                          onChange={e =>
                            setGiftMessageData({
                              ...giftMessageData,
                              sender: e.target.value,
                            })
                          }
                          placeholder="Sender name"
                          disabled={!giftFieldsEditable}
                        ></Input>
                        <Input
                          type="text"
                          value={giftMessageData.recipient}
                          variant="giftMessage"
                          onChange={e =>
                            setGiftMessageData({
                              ...giftMessageData,
                              recipient: e.target.value,
                            })
                          }
                          placeholder="Recipient name"
                          disabled={!giftFieldsEditable}
                        ></Input>
                        <Textarea
                          value={giftMessageData.message}
                          bg="#fffcf7"
                          onChange={e =>
                            setGiftMessageData({
                              ...giftMessageData,
                              message: e.target.value,
                            })
                          }
                          placeholder="Recipient message"
                          disabled={!giftFieldsEditable}
                        ></Textarea>
                        <Flex w="100%" mt="0">
                          {giftFieldsEditable ? (
                            <Button
                              w="full"
                              mr="0"
                              mt="8px"
                              disabled={canSaveGiftMessage ? false : true}
                              onClick={() => handleGiftMessageSave(giftCardProducts)}
                            >
                              {cartLoading || mutationLoading ? "Saving..." : "Save"}
                            </Button>
                          ) : (
                            <Button w="full" mt="8px" onClick={() => setEditGiftFields(true)}>
                              Edit
                            </Button>
                          )}
                        </Flex>
                      </>
                    )}
                  </VStack>
                </Box>
              )}

              <Box
                bg={["unset", "unset", "brand.lightSand"]}
                maxW={["unset", "unset", 76]}
                overflow="hidden"
                px={[0, 0, 6]}
                pt={[8, 6]}
                pb={[2, 2, 12]}
                minW={76}
              >
                <CartSummary isMain />
                {checkoutValueReached ? (
                  <CheckoutBtn />
                ) : (
                  <Button variant="solid" w="full" opacity="0.5" pointerEvents="none" cursor="not-allowed">
                    Checkout
                  </Button>
                )}

                <Box mt={[7, 7, 3]}>
                  <PaymentTypes showTitle />
                </Box>
                {isMedium && <ContinueShoppingButton />}
              </Box>
            </GridItem>
          )}
        </Grid>
      </Container>
      {cartHasItems && <CartRecommendations location={location} button={page?.button} />}
    </>
  )
}

export default memo(Cart)
