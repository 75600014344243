import { useState } from "react"
import { Box } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { CustomCheckbox } from "@components/Checkbox"
import { useCheckout } from "@app/hooks/useCheckout"
import { useConfigContext } from "@app/providers/config"
import { memo } from "react"

const CartAuthorityToLeave = () => {
  const [atlEnabled, setAtlEnabled] = useState(false)
  const locales = useLocale(LOCALE_KEYS.CART)
  const { updateAttributes } = useCheckout()
  const {
    settings: {
      attributes: { authorityToLeave },
    },
  } = useConfigContext()

  const handleAtlChange = () => {
    setAtlEnabled(!atlEnabled)
    updateAttributes({
      customAttributes: [
        { key: authorityToLeave, value: (!atlEnabled)?.toString() },
        { key: "Authority To Leave", value: atlEnabled ? "No" : "Yes" },
      ],
    })
  }

  return (
    <Box pt={4} pb={4}>
      <CustomCheckbox name="atl" handleChange={handleAtlChange} checked={atlEnabled} fontSize="sm">
        {locales?.authorityToLeave}
      </CustomCheckbox>
    </Box>
  )
}

const MemoCartAuthorityToLeave = memo(CartAuthorityToLeave)
export { MemoCartAuthorityToLeave as CartAuthorityToLeave }
