import { memo, useCallback } from "react"
import { Button } from "@chakra-ui/react"
import { useCheckoutContext } from "@app/providers/checkout"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useSubscriptionContext } from "@app/providers/subscription"

const CheckoutBtn: React.FC = () => {
  const { checkout, loading, gotoCheckout } = useCheckoutContext()
  const { processRechargeCheckout } = useSubscriptionContext()
  const locales = useLocale(LOCALE_KEYS.CART)

  const handleCheckoutClick = useCallback(
    event => {
      const { shouldGoToNormalCheckout } = processRechargeCheckout({ lineItems: checkout?.lineItems, isMainCart: true, isForced: true })

      if (shouldGoToNormalCheckout) {
        return gotoCheckout(event)
      }
    },
    [checkout, processRechargeCheckout, gotoCheckout]
  )

  return (
    <Button
      isDisabled={!checkout?.lineItems?.length}
      w="full"
      as="a"
      mt={5}
      onClick={handleCheckoutClick}
      isLoading={loading}
      cursor="pointer"
    >
      {locales?.cartCheckout}
    </Button>
  )
}

const MemoCheckoutBtn = memo(CheckoutBtn)
export { MemoCheckoutBtn as CheckoutBtn }
